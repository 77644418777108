/* iPhone Trick for click been converted to touchstart issue */
/iP/i.test(navigator.userAgent) && $('*').css('cursor', 'pointer');

/*
 * Mobile menu
 */
var menu = {
  $el: $('.header__menu'),
  open: function () {
    this.$el.removeClass('closed');
  },
  close: function () {
    this.$el.addClass('closed');
  }
}

$(function() {
  $('body').on('click', function (e) {
    $more.find('ul').hide();
    $more.removeClass('active');
    $('.modal-mask').fadeOut(300);

    var isOutside = ! $(e.target).closest('.header__menu').length;
    var isToggleBtn = $(e.target).closest('.header__menu-toggler').length;
    if (isOutside && ! isToggleBtn) {
      menu.close();
    }
  });
  $('body').on('click', '.header__menu-toggler', function () {
    menu.open();
  });
  $('body').on('click', '.header__menu__close-btn', function () {
    menu.close();
  });
});


/*
 * Replace all SVG images with inline SVG
 */
jQuery('img.svg').each(function(){
  var $img = jQuery(this);
  var imgID = $img.attr('id');
  var imgClass = $img.attr('class');
  var imgURL = $img.attr('src');

  jQuery.get(imgURL, function(data) {
    // Get the SVG tag, ignore the rest
    var $svg = jQuery(data).find('svg');

    // Add replaced image's ID to the new SVG
    if(typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
    }
    // Add replaced image's classes to the new SVG
    if(typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass+' replaced-svg');
    }

    // Remove any invalid XML tags as per http://validator.w3.org
    $svg = $svg.removeAttr('xmlns:a');

    // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
    if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
    }

    // Replace image with new SVG
    $img.replaceWith($svg);

  }, 'xml');
});

/*
 * Dropdown menu
 */
var $more = $('.header__menu .more');
$more.on('click', 'a', function(e) {
  e.stopPropagation();
  $more.find('ul').toggle();
  $more.toggleClass('active');
});

/*
 * Go to the top Arrow
 */
// $('#top').hide();

// $(window).scroll(function () {
//     var t = 800 > $(window).width() && $(window).scrollTop() + $(window).height() >= $(document).height() + 50;
//     if($(window).scrollTop() >= 600 && !t) {
//         $('#openBtnDiv').hide();
//         $('#top').fadeIn(500);
//     } else  {
//         $('#openBtnDiv').show();
//         $('#top').fadeOut(200);
//     }
// });

// $('#top').on('click', function (e) {
//     e.preventDefault();
//     $('html, body').animate({
//         scrollTop: $($(this).attr('href')).offset().top
//     }, 1000);
// });

/*
 * Login Modal
 */
// $('.login_link').on('click', function(e) {
//     e.preventDefault();
//     e.stopPropagation();
//     $('.more ul').css('display', 'none');
//     $('.modal-mask').fadeIn(300);
// });

// $('.modal-container').on('click', function(e) {
//     e.stopPropagation();
// });

// $('.login_modal_form').on('click', '*[type="submit"]', function() {
//     if($('.login_modal_form input[name="login"]').val() && $('.login_modal_form input[name="password"]').val()) {
//         $(this).find('span').hide();
//         $(this).find('.la-ball-fall').show();
//     }
// });

/*
 * Question
 */

$('.question__item_title').on('click', function(e) {
    e.preventDefault();
    var $questionItem = $(this).closest('.question__item');

    $questionItem.siblings().find('.question__item_answer').hide();
    $questionItem.toggleClass('active');
    $(this).next('.question__item_answer').toggle();
});

