if (Cookies.get('cookie_bar') === undefined) {
    $("body").append('<div id="cookies-warning" style="display: block;"><a href="#" id="cookies-warning-close">✕</a><div id="cookies-warning-text">Les cookies sont de petits fichiers texte stockés sur votre ordinateur afin de sauvegarder des données relatives à votre navigation. Ces données nous aident à vous offrir une meilleure expérience utilisateur dans le respect de notre <a href="/privacy-policy">politique de confidentialité. En naviguant sur ce site, vous déclarez que vous êtes d\'accord avec l\'utilisation de cookies.</div></div>');

    $("#cookies-warning-close").click(function (e) {
        e.preventDefault();
        $("#cookies-warning").fadeOut();
        Cookies.set('cookie_bar', "viewed", {expires: 30 * 13});
    });
}

//Code analytics
if(Cookies.get('cookie_bar') === "viewed"){
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    ga('create', 'UA-70691714-1', 'auto');
    ga('send', 'pageview');
}